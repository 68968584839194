import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import { pageTitle, PcToast } from '@je-pc/ui-components'
import { subscribeToUserUpdates } from '@je-pc/auth'

import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import { SET_USER } from './store/mutations.types'

Vue.use(PcToast, {
  compact: true,
  closeButton: null,
})
Vue.mixin(pageTitle)
Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
        },
      })
    },
    router,
    i18n,
    store,
  },
})

let userSubscription = null

export const bootstrap = vueLifecycles.bootstrap

export const mount = (prop) => {
  userSubscription = subscribeToUserUpdates((user) => {
    store.commit(SET_USER, user)
  })
  return vueLifecycles.mount(prop)
}

export const unmount = (prop) => {
  userSubscription?.unsubscribe()
  return vueLifecycles.unmount(prop)
}
