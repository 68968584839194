import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { beforeRouteEnterHook } from '@je-pc/auth'
import { trackPageTransition } from '@je-pc/tracking'
import { navigateToUrl } from 'single-spa'
import { GlobalFeatureManagementService } from '@rmp/services'
import { UserClient } from '@rmp/auth-client'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const hasChatbot =
    GlobalFeatureManagementService &&
    GlobalFeatureManagementService.partnerHubFeatureManager &&
    GlobalFeatureManagementService.partnerHubFeatureManager.getBooleanValue(
      'partner-chatbot-feature'
    )

  const userInfo = UserClient.getUserInfo()
  const isRestaurant =
    userInfo &&
    userInfo.business &&
    userInfo.business.establishmentType &&
    userInfo.business.establishmentType === 'restaurant'

  if (to.name === 'Send Message' && hasChatbot && isRestaurant) {
    return navigateToUrl('/support/chatbot')
  }

  beforeRouteEnterHook(to, from, next)
})

trackPageTransition(router)

export default router
