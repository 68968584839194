import { ApiClient } from '@je-pc/api'
import { tokenTypesConfig } from '@je-pc/auth'
import { SMARTGATEWAY_URL } from '../constants'

const httpKeyCloakClient = new ApiClient(SMARTGATEWAY_URL, true, true, {
  tokenType: tokenTypesConfig.list.keycloak,
})

export const getSupportRequests = (params) => {
  return httpKeyCloakClient.get(
    '/applications/partnerhelp/{tenant}/{restaurantId}/support/requests',
    {
      params: {
        ...params,
        limit: 10,
      },
    }
  )
}

export const getSupportRequestTypes = () => {
  return httpKeyCloakClient.get(
    'applications/partnerhelp/{tenant}/{restaurantId}/support/requests/types'
  )
}

export const submitSupportRequest = (params) => {
  return httpKeyCloakClient.post(
    'applications/partnerhelp/{tenant}/{restaurantId}/support/requests',
    params
  )
}

export const getSettings = (params) => {
  return httpKeyCloakClient.get(
    `/applications/partnercentre/{tenant}/{restaurantId}/settings?settings=${params}`
  )
}
