import Vue from 'vue'
import Vuex from 'vuex'
import {
  getSupportRequests,
  getSupportRequestTypes,
  getSettings,
} from '../services/http.js'
import {
  GET_SUPPORT_REQUESTS,
  GET_SUPPORT_REQUEST_TYPES,
  FETCH_SETTINGS,
} from './actions.types'
import {
  SET_SUPPORT_REQUESTS,
  SET_SUPPORT_REQUEST_TYPES,
  SET_USER,
  SET_SETTINGS,
} from './mutations.types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    supportRequests: {
      paging: {},
      requests: [],
    },
    supportRequestTypes: [],
    settings: {},
  },
  mutations: {
    [SET_SUPPORT_REQUESTS](state, requests) {
      state.supportRequests = { ...requests }
    },
    [SET_SUPPORT_REQUEST_TYPES](state, types) {
      state.supportRequestTypes = types
    },
    [SET_USER](state, user) {
      state.user = user
    },
    [SET_SETTINGS](state, settings) {
      state.settings = settings
    },
  },
  actions: {
    async [GET_SUPPORT_REQUESTS]({ commit }, params) {
      const response = await getSupportRequests(params)
      commit(SET_SUPPORT_REQUESTS, response.data)
    },
    async [GET_SUPPORT_REQUEST_TYPES]({ commit }) {
      const response = await getSupportRequestTypes()
      commit(SET_SUPPORT_REQUEST_TYPES, response.data)
    },
    async [FETCH_SETTINGS]({ state, commit }, settings) {
      const params = settings.join(',')
      const response = await getSettings(params)
      commit(SET_SETTINGS, { ...state.settings, ...response.data?.settings })
    },
  },
})
