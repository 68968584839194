const SupportRequests = () =>
  import(
    /* webpackChunkName: "supportRequests" */ '../views/SupportRequests.vue'
  )
const SendMessage = () =>
  import(/* webpackChunkName: "sendMessage" */ '../views/SendMessage.vue')

const routes = [
  {
    path: '/send-message',
    name: 'Send Message',
    component: SendMessage,
    meta: {
      name: 'contact us',
      group: 'business help',
    },
  },
  {
    path: '/support-requests',
    name: 'Support Requests',
    component: SupportRequests,
    meta: {
      name: 'support requests',
      group: 'business help',
    },
  },
]

export default routes
