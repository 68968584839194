export default {
  messages: {
    account_security: 'Account security',
    account_security_text: 'Protect yourself from scams and fraudsters',
    business_essentials: 'Business essentials',
    business_essentials_text: 'Tips, advice and resources',
    common_questions: 'Common questions',
    common_questions_text: 'Answers to popular queries',
    support_requests: 'Your support requests',
    support_requests_text:
      'Track the progress of your submitted support requests.',
    contact_us_title: 'Waited over 7 days?',
    contact_us_text:
      'If a support request is In progress for more than 7 days, please tell us. Remember to include the reference number (beginning #).',
    contact_us_button: 'Get in touch',
    new: 'New',
    completed: 'Completed',
    in_progress: 'In progress',
    archived: 'Archived',
    more_info_needed: 'More info needed',
    deleted: 'Deleted',
    of: 'of',
    items: 'items',
    no_requests_title: 'You haven’t made any support requests',
    no_requests_text:
      'When you do, they’ll be listed here. If you need help with anything,',
    send_message: 'Send us a message',
    send_message_text: 'We’re here to help',
    send_message_button: 'Send message',
    send_message_successful_sending:
      'Thank you for your message. We will get back to you shortly.',
    your_email: 'Your email address',
    your_email_text:
      'Make sure you can access the email address above so we can contact you.',
    type_label: 'What can we help you with?',
    type_placeholder: 'Please select a topic',
    message_label: 'Your message',
    message_placeholder:
      'Write your message here. Give us as much information as you can so we can help you better.',
    terms_and_conditions: 'Terms and conditions',
    accept_terms_title: 'Accept Terms and Conditions',
    accept_terms_text:
      'Please read to the bottom of these terms and conditions and accept to continue.',
    accept_terms_button: 'Accept',
    privacy_policy: 'Privacy policy',
    cookies_policy: 'Cookies policy',
    rmm_availability: {
      title: 'Did you know you can make changes to your menu on your own?',
      message:
        'From price changes to adding products and meal deals, you can do it all yourself right here.',
      button: 'Try it out now',
    },
    form: {
      error_title: 'There are some errors',
      error_message: 'Please check the form below.',
      invalid_email: 'Please enter a valid email',
      invalid_phone: 'Please enter a valid contact number',
      required_field: 'This is required',
      ownership: {
        form_info: 'Why are you filling in this form?',
        select_placeholder: 'Select one',
        select_transfer:
          'I have transferred, or plan to transfer my Restaurant to a new owner',
        select_buy: 'I have just bought the Restaurant',
        current_owner_information: 'Your information',
        required: 'required',
        optional: 'optional',
        current_owner_name: 'Full name of the current owner',
        current_owner_name_placeholder: 'Enter current owners full name',
        current_owner_email: 'Current owners email address',
        current_owner_email_placeholder: 'Enter current owners email address',
        new_owner_information: 'New owner information',
        new_owner_name: 'Full name of the new owner',
        new_owner_name_placeholder: 'Enter new owners full name',
        new_owner_email: 'New owners email address',
        new_owner_email_placeholder: 'Enter new owners email address',
        new_owner_phone: 'New owners landline/mobile number',
        new_owner_phone_placeholder: 'Enter their landline/mobile number',
        date_of_transfer_title: 'Date of transfer',
        date_of_transfer_combined:
          'Date when transfer occurred (past) or is intended to occur (future)',
        consent_to_contact_title: 'Consent to contact',
        consent_to_contact_checkbox:
          'If you are the current owner, please confirm that the new owner has given you their permission to pass on their details in order for us to contact them regarding this Change of Ownership',
        submit_form_info_1:
          'When you have submitted this form to us we will attempt to contact the new owner of the Restaurant to confirm this transfer.',
        submit_form_info_2:
          'Please note that in the event of any disagreement as to the change of ownership, including the specific Change of Ownership Date, we may need to contact you to obtain further evidence. This may result in a delay to your payments, or to monies being paid to the incorrect party. Just Eat will always use reasonable endeavours to determine when the correct Change of Ownership Date is, but the best way to avoid dispute is to provide clear evidence of the transfer (e.g. a signed and dated sale agreement).',
        submit_form_transfer:
          'Restaurant is transferred or going to be transferred to the new owner. \n ' +
          'Current owner:\n ' +
          'Name: {CurrentOwnerName}\n ' +
          'Email: {CurrentOwnerEmail}\n ' +
          'New owner:\n ' +
          'Name: {NewOwnerName}\n ' +
          'Email: {NewOwnerEmail}\n ' +
          'Landline/Mobile: {NewOwnerMobile}\n ' +
          'Date when transfer occurred: {DateOfTransfer}',
        submit_form_bought:
          'New owner just bought the restaurant\n ' +
          'New owner:\n ' +
          'Name: {NewOwnerName}\n ' +
          'Email: {NewOwnerEmail}\n ' +
          'Landline/Mobile: {NewOwnerMobile}\n ' +
          'Date when transfer occurred: {DateOfTransfer}',
      },
      business_details: {
        notification_title: 'Change your business name or address',
        notification_message:
          'To update your business name or address, you can now use the request form from the Change business details page.',
        notification_button: 'Try it out now',
      },
    },
  },
  dateTimeFormats: {
    weekday: {
      weekday: 'short',
    },
    day: {
      day: 'numeric',
    },
    month_and_year: {
      month: 'long',
      year: 'numeric',
    },
    month_long: {
      month: 'long',
    },
    month_short: {
      month: 'short',
    },
    year: {
      year: 'numeric',
    },
  },
}
